import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { Order } from './order';
import styles from './style.css';

export interface IOrdersListParams {
    config: ISalesLayoutConfig;
    ordersList: ICustomerOrder[];
}

export const OrdersList: React.FC<IOrdersListParams> = (
    {
        config,
        ordersList,
    },
) => {
    if (!ordersList || !ordersList.length) {
        return null;
    }

    return (
        <div className={styles.ordersList}>
            {ordersList.map(
                (order: ICustomerOrder) => (
                    <Order config={config} order={order} />
                ),
            )}
        </div>
    );
};
