import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { useOrdersLoader } from 'ui/page/sales-pages/orders-page/orders-loader';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    TAB_MY_ORDERS,
    TAB_BUY_AGAIN,
    TAB_NOT_SHIPPED_YET,
    TAB_CANCELLED_ORDERS,
    ordersTabs,
    currentOrdersTabVar,
} from 'ui/page/sales-pages/orders-page/orders-state';
import { TabButton } from './tab-button';
import { MyOrders } from './tabs-content/my-orders';
import { BuyAgain } from './tabs-content/buy-again';
import { NotShippedYetOrders } from './tabs-content/not-shipped-yet';
import { CancelledOrders } from './tabs-content/cancelled-oders';
import {
    getCurrentOrdersTabFromUrl,
    navigateToOrderTab,
} from './util/tabs-params-resolver';
import styles from './style.css';

interface IOrdersTabsProps {
    config: ISalesLayoutConfig;
}
export interface IOrdersTabProps {
    config: ISalesLayoutConfig;
    tabKey: string;
    ordersPageLoading: boolean;
    setOrdersPageLoading: (ordersPageLoading: boolean) => void;
}

interface IOrdersTabTitle {
    [key: string]: string;
}

const ordersTabsComponents: {[key: string]: React.ElementType} = {
    [TAB_MY_ORDERS]: MyOrders,
    [TAB_BUY_AGAIN]: BuyAgain,
    [TAB_NOT_SHIPPED_YET]: NotShippedYetOrders,
    [TAB_CANCELLED_ORDERS]: CancelledOrders,
};

export const OrdersTabs: React.FC<IOrdersTabsProps> = ({ config }) => {
    const { ordersPageLoading, setOrdersPageLoading } = useOrdersLoader();
    const currentOpenTabKey: string = useReactiveVar(currentOrdersTabVar);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const ordersTabsTitles: IOrdersTabTitle = {
        [TAB_MY_ORDERS]: t('Orders'),
        [TAB_BUY_AGAIN]: t('Buy Again'),
        [TAB_NOT_SHIPPED_YET]: t('Not Shipped Yet'),
        [TAB_CANCELLED_ORDERS]: t('Cancelled Orders'),
    };

    const getTabTitle = (tabKey: string) => ordersTabsTitles[tabKey];

    const getOrdersTabComponentByKey = (tabKey: string): React.ElementType => ordersTabsComponents[tabKey];

    const onClickOrdersTab = (
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>,
        tabKey: string,
    ): void => {
        event.preventDefault();
        if (currentOpenTabKey !== tabKey) {
            /**
             * Navigate to the clicked tab without updating current tab variable, it will be updated
             * in `useEffect` on window.location.search change
             */
            navigateToOrderTab(navigate, tabKey);
        }
    };

    useEffect((): void => {
        const currentTab: string = getCurrentOrdersTabFromUrl();
        if (currentOpenTabKey === '') {
            /**
             * Setup tab key param on first load (by navigate)
             */
            navigateToOrderTab(navigate, currentTab);
        }
    }, []);

    useEffect((): void => {
        const currentTab: string = getCurrentOrdersTabFromUrl();
        if (currentOpenTabKey !== currentTab) {
            /**
             * Once the current tab has changed on click on the tab - we change variable
             * to reload tab and its content
             */
            currentOrdersTabVar(currentTab);
        }
    }, [window.location.search]);

    return (
        <>
            <div className={styles.ordersTabsWrapper} data-test="orders-tabs">
                <div className={styles.ordersTabs}>
                    {ordersTabs.map(
                        (tabKey: string) => (
                            <TabButton
                                tabKey={tabKey}
                                tabTitle={getTabTitle(tabKey)}
                                isActive={tabKey === currentOpenTabKey}
                                onClickOrdersTab={onClickOrdersTab}
                            />
                        ),
                    )}
                </div>
                <div className={styles.ordersTabContent}>
                    {currentOpenTabKey &&
                        React.createElement(getOrdersTabComponentByKey(currentOpenTabKey), {
                            config,
                            tabKey: currentOpenTabKey,
                            ordersPageLoading,
                            setOrdersPageLoading,
                        })
                    }
                </div>
            </div>
        </>
    );
};
