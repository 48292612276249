import React, { useEffect } from 'react';
import { ReactiveVar, useReactiveVar, useLazyQuery } from '@apollo/client';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { Pagination } from 'ui/component/pagination';
import { IPageState, TypeUpdatePageState } from 'graphql/api/page-interfaces';
import {
    ICustomerOrdersInput,
    ICustomerOrder,
    ICustomerOrdersPerPage,
    TypeUpdatePageOrders,
} from 'graphql/customer/customerOrdersInterfaces';
import { GET_CUSTOMER_ORDERS } from 'graphql/customer/customerOrders';
import { transformCustomerOrders } from 'graphql/customer/util/transform-customer-orders';
import { OrdersList } from 'ui/component/orders-list';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { resolveCurrentPage, setCurrentPage } from '../../util/tabs-params-resolver';
import tabsStyle from '../../style.css';

export interface ITabOrdersProps {
    config: ISalesLayoutConfig;
    tabKey: string;
    variables: ICustomerOrdersInput;
    pageOrdersStateVar: ReactiveVar<IPageState>;
    pageOrdersVar: ReactiveVar<ICustomerOrdersPerPage[]|null>;
    ordersPageLoading: boolean;
    setOrdersPageLoading: (ordersPageLoading: boolean) => void;
    notAvailableMessage: string;
}
export const TabOrders: React.FC<ITabOrdersProps> = (
    {
        config,
        tabKey,
        variables,
        pageOrdersStateVar,
        pageOrdersVar,
        ordersPageLoading,
        setOrdersPageLoading,
        notAvailableMessage,
    },
) => {
    const pageOrdersState: IPageState = useReactiveVar(pageOrdersStateVar);
    const pageOrders: ICustomerOrdersPerPage[]|null = useReactiveVar(pageOrdersVar);
    const { currentPage, pageSize, totalCount } = pageOrdersState;

    const navigate: Navigate = useNavigate();

    const updatePageState: TypeUpdatePageState = (newPageState: IPageState): void => {
        pageOrdersStateVar(newPageState);
    };

    const updatePageOrders: TypeUpdatePageOrders = (newPageOrders: ICustomerOrdersPerPage[]): void => {
        pageOrdersVar(newPageOrders);
    };

    const [getPageOrders] = useLazyQuery(GET_CUSTOMER_ORDERS, {
        variables: {},
        fetchPolicy: 'no-cache',
        onError: () => {
            updatePageOrders([]);
            updatePageState({
                ...pageOrdersState,
                totalCount,
            });
        },
        onCompleted: (data): void => {
            const { customer } = data;
            const { orders } = customer;
            const { page_info: pageInfo, total_count: pageTotalCount } = orders;
            const { current_page: pageInfoCurrentPage } = pageInfo;
            const page: number = pageInfoCurrentPage ?? currentPage;
            const totalCount: number = pageTotalCount ?? 0;
            const ordersPerPage: ICustomerOrdersPerPage[] = pageOrders ?? [];
            ordersPerPage[page] = transformCustomerOrders(orders);
            updatePageOrders(ordersPerPage);
            updatePageState({
                ...pageOrdersState,
                totalCount,
            });
        },
    });

    const ordersLoaded = (): boolean => Boolean(pageOrders && pageOrders[currentPage]);

    const loadPageOrders = async (): Promise<void> => {
        setOrdersPageLoading(true);
        const ordersVariables: ICustomerOrdersInput = {
            ...variables,
            currentPage,
            pageSize,
        };
        await getPageOrders({
            variables: ordersVariables,
        });
    };

    const onPageChange = (pageNumber: number): void => {
        if (currentPage !== pageNumber) {
            setCurrentPage(pageOrdersState, updatePageState, pageNumber, navigate);
        }
    };

    const getCurrentPageOrders = (): ICustomerOrdersPerPage|null => (
        pageOrders && pageOrders[currentPage]
            ? pageOrders[currentPage]
            : null
    );

    const currentPageOrders: ICustomerOrdersPerPage|null = getCurrentPageOrders();
    const ordersList: ICustomerOrder[] = currentPageOrders?.items ?? [];
    const hasOrders: Boolean = Boolean(ordersList && ordersList.length > 0);

    useEffect(() => {
        if (ordersLoaded() || currentPage === 0) {
            setOrdersPageLoading(false);
            return;
        }

        loadPageOrders().finally((): void => {
            setOrdersPageLoading(false);
        });
    }, [currentPage]);

    useEffect(() => {
        resolveCurrentPage(pageOrdersState, updatePageState, navigate);
    }, [totalCount]);

    return (
        <div className={tabsStyle.tabContent} data-test={`tab-content-${tabKey}`}>
            {ordersPageLoading && <StandardLoadingImage />}
            {!ordersPageLoading && (
                <div className={tabsStyle.customerOrdersWrapper}>
                    {hasOrders && (
                        <div className={tabsStyle.customerOrders}>
                            <OrdersList ordersList={ordersList} config={config} />
                            <Pagination
                                onPageChange={onPageChange}
                                totalCount={totalCount}
                                siblingCount={1}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                className={tabsStyle.paginationWrapper}
                                showPrev={false}
                                showNext={false}
                            />
                        </div>
                    )}
                    {!hasOrders && (
                        <div className={tabsStyle.customerHasNoOrders}>
                            <h3>{notAvailableMessage}</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
