import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrdersInput } from 'graphql/customer/customerOrdersInterfaces';
import {
    notShippedYetOrdersTabStateVar,
    notShippedYetOrdersPerPageVar,
} from 'ui/page/sales-pages/orders-page/orders-state';
import { TabOrders } from '../tab-orders';
import { IOrdersTabProps } from '../../orders-tabs';

export const NotShippedYetOrders: React.FC<IOrdersTabProps> = (
    {
        config,
        tabKey,
        ordersPageLoading,
        setOrdersPageLoading,
    },
) => {
    const t = usePhraseTranslater();
    const variables: ICustomerOrdersInput = {
        filter: {
            state: {
                in: [
                    'new',
                    'payment_review',
                    'pending_payment',
                ],
            },
        },
        sort: {
            sort_field: 'CREATED_AT',
            sort_direction: 'DESC',
        },
    };
    return (
        <TabOrders
            config={config}
            tabKey={tabKey}
            variables={variables}
            pageOrdersStateVar={notShippedYetOrdersTabStateVar}
            pageOrdersVar={notShippedYetOrdersPerPageVar}
            ordersPageLoading={ordersPageLoading}
            setOrdersPageLoading={setOrdersPageLoading}
            notAvailableMessage={t("You have no 'not shipped yet' orders.")}
        />
    );
};
