import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { ICustomerBuyAgainOrderItem, IProductImageSwatch } from 'graphql/customer/customerBuyAgainOrderItems';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import styles from './style.css';

export interface IBuyAgainOrderItemParams {
    config: ISalesLayoutConfig;
    buyAgainItem: ICustomerBuyAgainOrderItem;
}

export const BuyAgainOrderItem: React.FC<IBuyAgainOrderItemParams> = (
    {
        buyAgainItem,
    },
) => {
    const navigate: Navigate = useNavigate();

    const {
        productUid,
        productType,
        productSku,
        productName,
        productPrices,
        productUrl,
        productImageUrl,
        productSwatches,
    } = buyAgainItem;
    const {
        price,
        minPrice,
        maxPrice,
    } = productPrices;

    const hasUrl = productUrl && productUrl.length > 0;
    const hasSwatches = productType === 'configurable' && productSwatches.length > 0;

    const getProductPrice = () => {
        if (productType === 'simple' || minPrice === maxPrice) {
            return (
                <SummaryLine
                    amount={price}
                />
            );
        }

        return (
            <>
                <SummaryLine
                    amount={minPrice}
                />
                <span> - </span>
                <SummaryLine
                    amount={maxPrice}
                />
            </>
        );
    };

    const productFinalPrice = getProductPrice();

    const goToProduct = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        navigateToUrl({
            navigate,
            url: productUrl,
        });
    };

    const getBackgroundUrl = (thumbUrl: string): { [key: string]: string } => ({
        'background-image': `url(${thumbUrl})`,
    });

    if (!buyAgainItem) {
        return null;
    }

    return (
        <div className={styles.buyAgainItemWrapper} data-test={`buy-again-product-sku-${productSku}`}>
            <AccessibleButton
                tag="button"
                disabled={!hasUrl}
                data-test={`buy-again-product-id-${productUid}`}
                className={classes(styles.productImageUrlBtn)}
                action={goToProduct}
            >
                {productImageUrl && (
                    <img
                        className={styles.productImage}
                        src={productImageUrl}
                        alt={productName}
                        width={280}
                        height={420}
                    />
                )}
            </AccessibleButton>
            <div className={styles.buyAgainItemDetails}>
                <div className={classes(styles.detailItem, styles.buyAgainItemName)}>
                    <a className={styles.productLink} href={productUrl}>
                        <span className={styles.productName}>{productName}</span>
                    </a>
                </div>
                <div className={classes(styles.detailItem, styles.buyAgainItemPrice)}>
                    <div className={styles.productPrice}>{productFinalPrice}</div>
                </div>
                {hasSwatches && (
                    <div className={classes(styles.detailItem, styles.buyAgainItemSwatches)}>
                        {productSwatches.map(
                            (swatch: IProductImageSwatch) => {
                                const {
                                    thumb,
                                    label,
                                } = swatch;
                                return (
                                    <div className={styles.productSwatchWrapper}>
                                        <a className={styles.productSwatchLink} href={productUrl}>
                                            <div
                                                className={styles.productSwatchThumb}
                                                data-test={`swatch-image-${label}`}
                                                style={{
                                                    ...getBackgroundUrl(thumb),
                                                }}
                                            />
                                        </a>
                                    </div>
                                );
                            },
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
