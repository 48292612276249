import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { OrdersTabs } from 'ui/component/account/customer/orders-tabs';
import { SalesLayout } from '../layouts/sales-layout';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../layouts/components/page-content';
import { InitializeOrdersPage } from './orders-state';
import { LoaderContext } from './orders-loader';
import styles from './style.css';

const OrdersPage: React.FC = () => {
    const [ordersPageLoading, setOrdersPageLoading] = useState(false);
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const t = usePhraseTranslater();
    const pageTitle = t('Your Orders');

    const ordersPageComponent: JSX.Element = (
        <LoaderContext.Provider value={{ ordersPageLoading, setOrdersPageLoading }}>
            <InitializeOrdersPage>
                <PageContent
                    pageTitle={pageTitle}
                    pageLoading={false}
                    pageContentClassName={styles.ordersTabs}
                >
                    <OrdersTabs config={config} />
                </PageContent>
            </InitializeOrdersPage>
        </LoaderContext.Provider>
    );

    return (
        <SalesLayout
            pageKey="your-orders"
            pageTitle={pageTitle}
            pageContent={ordersPageComponent}
            isGuestPage={false}
            loadCountries
            loadOrder={false}
        />
    );
};

export { OrdersPage as default };
