import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface IOrdersTabParams {
    tabKey: string;
    tabTitle: string;
    isActive: boolean;
    onClickOrdersTab: (
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>,
        tabKey: string,
    ) => void;
}

export const TabButton: React.FC<IOrdersTabParams> = (
    {
        tabKey,
        tabTitle,
        isActive,
        onClickOrdersTab,
    },
) => (
    <div
        className={classes(styles.ordersTab, {
            [styles.openTab]: isActive,
        })}
    >
        <button
            type="button"
            data-test={`tab-button-${tabKey}`}
            className={styles.tabButton}
            tabIndex={0}
            key={tabKey}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClickOrdersTab(event, tabKey)}
            onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => onClickOrdersTab(event, tabKey)}
        >
            <span className={styles.ordersTabTitle}>{tabTitle}</span>
        </button>
    </div>
);
