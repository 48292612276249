import { gql, TypedDocumentNode } from '@apollo/client';
import { ICustomerOrdersInput, ISortVar } from './customerOrdersInterfaces';


export interface ISortBuyAgainVar extends ISortVar {
    // eslint-disable-next-line camelcase
    sort_field: 'CREATED_AT';
}

export interface IBuyAgainItemsInput extends ICustomerOrdersInput {
    sort?: ISortBuyAgainVar;
}

export interface IProductPrices {
    price: number;
    minPrice: number;
    maxPrice: number;
}

export interface IProductImageSwatch {
    type: number;
    thumb: string;
    value: string;
    label: string;
}

export interface ICustomerBuyAgainOrderItem {
    productId: string;
    productUid: string;
    createdAt: string;
    status: string;
    productType: string;
    productSku: string;
    productName: string;
    productPrices: IProductPrices;
    productUrl: string;
    productImageUrl: string;
    productSwatches: IProductImageSwatch[];
}

export interface ICustomerBuyAgainOrderItems {
    items: ICustomerBuyAgainOrderItem[];
    pageInfo: {
        pageSize: number;
        currentPage: number;
    };
    totalCount: number;
}

export interface IBuyAgainItemsOutput {
    customerBuyAgainOrderItems: ICustomerBuyAgainOrderItems;
}

export const GET_CUSTOMER_BUY_AGAIN_ORDER_ITEMS: TypedDocumentNode<IBuyAgainItemsOutput, IBuyAgainItemsInput> = gql`
    query GET_CUSTOMER_BUY_AGAIN_ORDER_ITEMS(
        $filter: CustomerOrdersFilterInput
        $currentPage: Int = 1
        $pageSize: Int = 24
        $sort: CustomerOrderSortInput
        $scope: ScopeTypeEnum
    ) {
        customerBuyAgainOrderItems(
            filter: $filter
            currentPage: $currentPage
            pageSize: $pageSize
            sort: $sort
            scope: $scope
        ) {
            items {
                productId
                productUid
                createdAt
                status
                productType
                productSku
                productName
                productPrices {
                    price
                    minPrice
                    maxPrice
                }
                productUrl
                productImageUrl
                productSwatches {
                    type
                    thumb
                    value
                    label
                }
            }
            pageInfo {
                pageSize
                currentPage
            }
            totalCount
        }
    }
`;
