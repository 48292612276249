import React, { createContext, useContext } from 'react';

interface IOrdersLoaderContext {
    ordersPageLoading: boolean;
    setOrdersPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IOrdersLoaderContext | undefined>(undefined);

export const useOrdersLoader = (): IOrdersLoaderContext => useContext(LoaderContext) as IOrdersLoaderContext;
