import React, { useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useReactiveVar, useLazyQuery } from '@apollo/client';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { StandardLoadingImage } from 'ui/component/loading-image';
import {
    GET_CUSTOMER_BUY_AGAIN_ORDER_ITEMS,
    IBuyAgainItemsInput,
    ICustomerBuyAgainOrderItem,
    ICustomerBuyAgainOrderItems,
} from 'graphql/customer/customerBuyAgainOrderItems';
import { IPageState, TypeUpdatePageState } from 'graphql/api/page-interfaces';
import { Pagination } from 'ui/component/pagination';
import { buyAgainItemsTabStateVar, buyAgainItemsPerPageVar } from 'ui/page/sales-pages/orders-page/orders-state';
import { BuyAgainOrderItemsList } from 'ui/component/buy-again-order-items-list';
import { IOrdersTabProps } from '../../orders-tabs';
import { resolveCurrentPage, setCurrentPage } from '../../util/tabs-params-resolver';
import tabsStyle from '../../style.css';

export const BuyAgain: React.FC<IOrdersTabProps> = (
    {
        config,
        tabKey,
        ordersPageLoading,
        setOrdersPageLoading,
    },
) => {
    const buyAgainItemsTabState: IPageState = useReactiveVar(buyAgainItemsTabStateVar);
    const buyAgainItemsPerPage: ICustomerBuyAgainOrderItems[]|null = useReactiveVar(buyAgainItemsPerPageVar);
    const { currentPage, pageSize, totalCount } = buyAgainItemsTabState;

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const updatePageState: TypeUpdatePageState = (newPageState: IPageState): void => {
        buyAgainItemsTabStateVar(newPageState);
    };

    const updatePageOrders = (newBuyAgainItems: ICustomerBuyAgainOrderItems[]): void => {
        buyAgainItemsPerPageVar(newBuyAgainItems);
    };

    const [getCustomerBuyAgainOrderItems] = useLazyQuery(GET_CUSTOMER_BUY_AGAIN_ORDER_ITEMS, {
        variables: {},
        fetchPolicy: 'no-cache',
        onError: () => {
            updatePageOrders([]);
            updatePageState({
                ...buyAgainItemsTabState,
                totalCount,
            });
        },
        onCompleted: (data): void => {
            const { customerBuyAgainOrderItems } = data;
            const { pageInfo, totalCount: pageTotalCount } = customerBuyAgainOrderItems;
            const { currentPage: pageInfoCurrentPage } = pageInfo;
            const page: number = pageInfoCurrentPage ?? currentPage;
            const totalCount: number = pageTotalCount ?? 0;
            const buyAgainItems: ICustomerBuyAgainOrderItems[] = buyAgainItemsPerPage ?? [];
            buyAgainItems[page] = customerBuyAgainOrderItems;
            updatePageOrders(buyAgainItems);
            updatePageState({
                ...buyAgainItemsTabState,
                totalCount,
            });
        },
    });

    const buyAgainItemsLoaded = (): boolean => Boolean(buyAgainItemsPerPage && buyAgainItemsPerPage[currentPage]);

    const loadBuyAgainOrderItems = async (): Promise<void> => {
        setOrdersPageLoading(true);
        const ordersVariables: IBuyAgainItemsInput = {
            ...{
                sort: {
                    sort_field: 'CREATED_AT',
                    sort_direction: 'DESC',
                },
            },
            currentPage,
            pageSize,
        };
        await getCustomerBuyAgainOrderItems({
            variables: ordersVariables,
        });
    };

    const onPageChange = (pageNumber: number): void => {
        if (currentPage !== pageNumber) {
            setCurrentPage(buyAgainItemsTabState, updatePageState, pageNumber, navigate);
        }
    };

    const getCurrentPageBuyAgainItems = (): ICustomerBuyAgainOrderItems|null => (
        buyAgainItemsPerPage && buyAgainItemsPerPage[currentPage]
            ? buyAgainItemsPerPage[currentPage]
            : null
    );

    const buyAgainItems: ICustomerBuyAgainOrderItems|null = getCurrentPageBuyAgainItems();
    const buyAgainItemsList: ICustomerBuyAgainOrderItem[] = buyAgainItems?.items ?? [];
    const hasBuyAgainItems: Boolean = Boolean(buyAgainItemsList && buyAgainItemsList.length > 0);

    useEffect(() => {
        if (buyAgainItemsLoaded() || currentPage === 0) {
            setOrdersPageLoading(false);
            return;
        }

        loadBuyAgainOrderItems().finally((): void => {
            setOrdersPageLoading(false);
        });
    }, [currentPage]);

    useEffect(() => {
        resolveCurrentPage(buyAgainItemsTabState, updatePageState, navigate);
    }, [totalCount]);

    return (
        <div className={tabsStyle.tabContent} data-test={`tab-content-${tabKey}`}>
            {ordersPageLoading && <StandardLoadingImage />}
            {!ordersPageLoading && (
                <div className={tabsStyle.customerOrdersWrapper}>
                    {hasBuyAgainItems && (
                        <div className={tabsStyle.customerOrders}>
                            <BuyAgainOrderItemsList config={config} itemsList={buyAgainItemsList} />
                            <Pagination
                                onPageChange={onPageChange}
                                totalCount={totalCount}
                                siblingCount={1}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                className={tabsStyle.paginationWrapper}
                                showPrev={false}
                                showNext={false}
                            />
                        </div>
                    )}
                    {!hasBuyAgainItems && (
                        <div className={tabsStyle.customerHasNoOrders}>
                            <h3>{t("You have no placed orders yet. No 'buy again' items available.")}</h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
