import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerBuyAgainOrderItem } from 'graphql/customer/customerBuyAgainOrderItems';
import { BuyAgainOrderItem } from './buy-again-item';
import styles from './style.css';

export interface IBuyAgainOrderItemsListParams {
    config: ISalesLayoutConfig;
    itemsList: ICustomerBuyAgainOrderItem[];
}

export const BuyAgainOrderItemsList: React.FC<IBuyAgainOrderItemsListParams> = (
    {
        config,
        itemsList,
    },
) => {
    if (!itemsList || !itemsList.length) {
        return null;
    }

    return (
        <div className={styles.buyAgainList}>
            {itemsList.map(
                (item: ICustomerBuyAgainOrderItem) => (
                    <BuyAgainOrderItem config={config} buyAgainItem={item} />
                ),
            )}
        </div>
    );
};
