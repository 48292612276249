import React from 'react';
import { makeVar, ReactiveVar } from '@apollo/client';
import { IPageState } from 'graphql/api/page-interfaces';
import { ICustomerOrdersPerPage } from 'graphql/customer/customerOrdersInterfaces';
import { ICustomerBuyAgainOrderItems } from 'graphql/customer/customerBuyAgainOrderItems';

interface IInitializeOrdersPageProps {
    children: React.ReactNode;
}

export const TAB_MY_ORDERS = 'my-orders';
export const TAB_BUY_AGAIN = 'buy-again';
export const TAB_NOT_SHIPPED_YET = 'not-shipped-yet';
export const TAB_CANCELLED_ORDERS = 'cancelled-orders';

export const ordersTabs: string[] = [
    TAB_MY_ORDERS,
    TAB_BUY_AGAIN,
    TAB_NOT_SHIPPED_YET,
    TAB_CANCELLED_ORDERS,
];

export const defaultPageState: IPageState = {
    pageSize: 5,
    currentPage: 0,
    totalCount: 0,
};

export const defaultBuyAgainPageState: IPageState = {
    pageSize: 24,
    currentPage: 0,
    totalCount: 0,
};

export const currentOrdersTabVar: ReactiveVar<string> = makeVar('');

export const myOrdersTabStateVar: ReactiveVar<IPageState> = makeVar(defaultPageState);
export const myOrdersPerPageVar: ReactiveVar<ICustomerOrdersPerPage[]|null> = makeVar(null as any);

export const buyAgainItemsTabStateVar: ReactiveVar<IPageState> = makeVar(defaultBuyAgainPageState);
export const buyAgainItemsPerPageVar: ReactiveVar<ICustomerBuyAgainOrderItems[]|null> = makeVar(null as any);

export const notShippedYetOrdersTabStateVar: ReactiveVar<IPageState> = makeVar(defaultPageState);
export const notShippedYetOrdersPerPageVar: ReactiveVar<ICustomerOrdersPerPage[]|null> = makeVar(null as any);

export const canceledOrdersTabStateVar: ReactiveVar<IPageState> = makeVar(defaultPageState);
export const canceledOrdersPerPageVar: ReactiveVar<ICustomerOrdersPerPage[]|null> = makeVar(null as any);


export const InitializeOrdersPage: React.FC<IInitializeOrdersPageProps> = ({ children }) => (
    <>{children}</>
);
